<template>
<div class="all">
  <!-- <div class="all">
          <nav-bar-back class="detail-nav">
    <div class="title" slot="title">
     <div slot="center">内部推荐</div>
    </div>
  </nav-bar-back> -->
   <nav-bar class="detail-nav">
        
    <div class="title" slot="center">
     <div slot="center">内部推荐</div>
    </div>
  </nav-bar>

  <div class="content">
     <div class="header" :style="{backgroundImage:'url(\''+img+'\')'}"></div>
     <div class="body">
         <van-cell-group>
         <van-cell >
     <p class="headerTitle">推荐人信息</p>
    </van-cell>
    </van-cell-group>
<van-field  
    v-model="company"
    rows="1"
    autosize
    label="单位"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="推荐人所在单位"
  />
  <van-field  
    v-model="name"
    rows="1"
    autosize
    label="姓名"
    type="text"
    class="field"
    input-align="right" 
    placeholder="推荐人姓名"
  />
  <van-field  
    v-model="workId"
    rows="1"
    autosize
    label="工号"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="推荐人工号"
  />
  <van-field  
    v-model="phone"
    rows="1"
    autosize
    label="手机号码"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="推荐人手机号码"
  />

    <van-cell-group style="margin-top:13px">
         <van-cell >
     <p class="headerTitle">被推荐人信息</p>
    </van-cell>
    </van-cell-group>

  <van-field  
    v-model="nameRecommed"
    rows="1"
    autosize
    label="姓名"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="被推荐人姓名"
  />
 
  <van-field  
    v-model="phoneRecommed"
    rows="1"
    autosize
    label="手机号码"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="被推荐人手机号码"
  />
   <van-field  
    v-model="iDCard"
    rows="1"
    autosize
    label="身份证号"
    type="textarea"
    class="field"
    input-align="right" 
    placeholder="被推荐人身份号码"
  />
  
     </div>
 

  </div>
  <van-button  class="commit" @click="commit">提交</van-button>
   </div>

 

  
</template>

<script>
import NavBar from 'common/navbar/NavBar'
import {recommended} from "network/others";
// 

import { Cell, CellGroup,Field,Button,DatetimePicker,Popup ,Picker, Toast} from 'vant';
export default {
    data () {
        return {
          id:'',
          status:false,
          company:'',
          name:'',
          workId:'',
          phone:'',
          nameRecommed:'',
          phoneRecommed:'',
          iDCard:'',
          img:require('@/assets/images/commit-info/recoment.png')

        }
    },
    created () {
       
    },
   components: {
         NavBar,
         [Field.name]:Field,
         [CellGroup.name]:CellGroup,
         [Button.name]:Button,
         [Cell.name]:Cell
       
   },
   
   methods: {
       checkPhone(phone){ 
    // var phone = document.getElementById('phone').value;
    if(!(/^1[3456789]\d{9}$/.test(phone))){ 
        return false; 
    } else{
      return true
    }
    },
  commit(){
       if(!this.company){
        Toast.fail('请填写推荐人的单位');
        return 
       }
       if(!this.name){
         Toast.fail("请填写推荐人姓名")
         return
       }
       if(!this.workId){
        Toast.fail("请填写推荐人工号")
        return
       }
       
        if(!this.checkPhone(this.phone)){
             Toast.fail("手机号码格式有误")
             return
           }
       if(!this.nameRecommed){
         Toast.fail("请填写被推荐人姓名")
         return
       }
       if(!this.phoneRecommed){
         Toast.fail("请填写被推荐人的手机号码")
         return
       }

    if(!this.iDCard){
      Toast.fail("请填写被推荐人的身份号码")
      return
    }

     recommended(this.company,this.name,this.workId,this.phone,this.nameRecommed,this.phoneRecommed,this.iDCard).then(res=>{
          if(res=='推荐成功'){
            // Toast.success('提交成功')
        this.$router.push({path:'/sucefulResult',query:{title:'内部推荐'}})

          }
        //  console.log('最后的结果',res)
    })





      
  }
       
   },
 

}
</script>

<style scoped>
.commit{
    width: 100%;
     background-image: linear-gradient(to right, rgb(243, 70, 31) , rgb(243, 40, 71));
    position: fixed;
    color: #fff;
    bottom: 0;
    left: 0;
    border: none;
    /* background-color: red; */
}
.headerTitle{
    font-size: 15px
}
.field{
    display: flex;
    align-items: center
}
.all{
    width: 100%
}
.content{
    width: 100%;
    margin-bottom: 60px;
}
.header{
    
    height: 130px;
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-image: url("~assets/images/commit-info/recoment.png"); */
    
}
</style>